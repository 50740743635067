<template>
  <div class="k-9sptci" v-loading="loading">
    <!-- <div style="font-size: 15px; color: #606266; padding: 10px">
      赛事：门派预选赛s1
    </div> -->
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="比赛日期" prop="match_time">
        <el-select
          clearable
          v-model="searchForm.match_time"
          placeholder="请选择比赛日期"
          @change="handleQuery"
        >
          <el-option
            v-for="item in dataTable"
            :key="item.match_time"
            :label="item.match_time"
            :value="item.match_time"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门派ID" prop="sect_id">
        <el-input
          v-model="searchForm.sect_id"
          clearable
          placeholder="门派ID"
          maxlength="6"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="门派名称" prop="sect_name">
        <el-input
          v-model="searchForm.sect_name"
          clearable
          placeholder="门派名称"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="结果" width="280">
        <template slot-scope="scope">
          <!-- <span> {{ resultSects[scope.row.result].value }}</span> -->
          <el-select
            class="resultLeft"
            v-if="type_id == 's2' ||type_id == 's3'|| type_id == 'qualifier' "
            v-model="scope.row.result"
            placeholder="请选择"
            @change="selectSect(scope.row.index, scope.row.result)"
          >
            <el-option
              v-for="item in resultSects"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage`"
            @click.native.stop="handleChangeScore(scope.row)"
            >修改积分</perButton
          >
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑门派</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="'修改积分'"
      :visible.sync="scoreChangeVisible"
      v-if="scoreChangeVisible"
      :close-on-click-modal="false"
      width="400px"
      v-loading="loading"
    >
      <el-form
        ref="scoreForm"
        :model="scoreChangeData"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="赛事：">
          <span v-if="type_id == 's1' || type_id == 's2' ||type_id == 's3' ">
            门派预选赛{{ type_id }}
          </span>
        </el-form-item>
        <el-form-item label="比赛日期：">
          {{ scoreChangeData.match_time }}
        </el-form-item>
        <el-form-item label="门派ID：">
          {{ scoreChangeData.sect_id }}
        </el-form-item>
        <el-form-item label="门派名称：">
          {{ scoreChangeData.sect_name }}
        </el-form-item>
        <el-form-item label="积分：">
          {{ scoreChangeData.score }}
        </el-form-item>
        <el-form-item
          label="修改为："
          prop="new_score"
          label-position="left"
          :rules="[
            {
              required: true,
              message: '请输入积分',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="scoreChangeData.new_score"
            placeholder="请输入积分"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveScore">确定</el-button>
          <el-button @click="scoreChangeVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="门派修改"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item
          label="门派ID"
          prop="sect_id"
          :rules="[
            {
              required: true,
              message: '请输入门派ID',
              trigger: ['blur'],
            },
            {
              max: 6,
              message: '最多6个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.sect_id"
            placeholder="请输入门派ID"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="门派名称"
          prop="sect_name"
          :rules="[
            {
              required: true,
              message: '请输入门派名称',
              trigger: ['blur'],
            },
            {
              max: 25,
              message: '最多25个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.sect_name"
            placeholder="请输入门派名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveEscort">确定</el-button>
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { isColumnShow } from "@/utils/column";
import { MessageBox } from "element-ui";
export default {
  name: "s1_team_rank",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },

  data() {
    return {
      season_id: '',
      userVisible: false,
      userData: {
        _id: "",
        index: "",
        sect_id: "",
        sect_name: "",
      },
      resultSects: [
        {
          id: 0,
          value: "暂无",
        },
        {
          id: 1,
          value: "胜",
        },
        {
          id: 2,
          value: "败",
        },
        {
          id: 3,
          value: "败：未押分",
        },
        {
          id: 4,
          value: "败：所押门派被取消资格",
        },
        {
          id: 5,
          value: "败：所押门派降为替补",
        },
        {
          id: 6,
          value: "无效：所押门派弃赛",
        },
        {
          id: 7,
          value: "无效：正赛门派无需押分",
        },
        {
          id: 8,
          value: "败：正赛门派降为替补",
        },
      ],
      match_index: "",
      team_id: "",
      stage: "",
      match_id: "",
      matchType: "",
      ids: [],
      type_id: "s1",
      loading: false,
      pageSize: 10,
      scoreChangeVisible: false,
      scoreChangeData: {
        team_id: "",
        sect_id: "",
        sect_name: "",
        score: "",
        new_score: "",
        match_time: "",
      },
      sortChangeVisible: false,
      sortChangeData: {
        team_id: "",
        sect_id: "",
        sect_name: "",
        sort: "",
      },

      searchForm: {
        sect_id: "",
        sect_name: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "match_time",
          label: "比赛日期",
        },
        {
          prop: "sect_id",
          label: "门派ID",
          formatter: (row) => {
            switch (row.sect_id) {
              case "":
                return "暂无";
              default:
                return row.sect_id;
            }
          },
        },
        {
          prop: "sect_name",
          label: "门派名称",
          formatter: (row) => {
            switch (row.sect_name) {
              case "":
                return "暂无";
              default:
                return row.sect_name;
            }
          },
          width: 300,
        },
        {
          prop: "score",
          label: "积分",
          width: 200,
        },
      ],
    };
  },
  mounted() {
     this.season_id  = this.$route.query.season_id,
    this.team_id = this.$route.query._id;
    this.match_id = this.$route.query.match_id;
    this.type_id = this.$route.query.type_id;
    this.stage = this.$route.query.stage;
    this.matchType = localStorage.getItem("matchType");
    this.handleQuery();
  },
  methods: {
    async selectSect(index, result) {
      try {
        await MessageBox.confirm("请确认是否要修改结果?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        let saveData = {
          _id: this.team_id,
          index: index,
          result: result,
        };
        let res = await this.$http.match.update_charge_result(saveData);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
        }
      } catch (err) {console.log(err);}
      this.handleQuery();
    },
    //编辑赋值
    handleEdit(row) {
      this.view_type = "edit";
      this.userData.sect_id = row.sect_id;
      this.userData.sect_name = row.sect_name;
      this.match_index = row.index;
      this.userVisible = true;
    },
    saveEscort() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          let saveData = {
            sect_id: this.userData.sect_id,
            sect_name: this.userData.sect_name,
            index: this.match_index,
            _id: this.team_id,
          };

          let { data, code } = await this.$http.match.update_charge_sect(
            saveData
          );
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.userVisible = false;
            this.handleQuery();
          }
        }
      });
    },
    async handleChangeScore(row) {
      this.scoreChangeData.sect_id = row.sect_id;
      this.scoreChangeData.sect_name = row.sect_name;
      this.scoreChangeData.score = row.score;
      this.scoreChangeData.new_score = row.score;
      this.scoreChangeData.team_id = row.team_id;
      this.scoreChangeData.match_time = row.match_time;
      this.match_index = row.index;
      this.scoreChangeVisible = true;
    },
    handleQuery() {
      this.queryList();
    },
    async queryList() {
      const { data } = await this.$http.match.stage_escort_sects({
        ...this.searchForm,
        match_id: this.match_id,
        team_id: this.team_id,
      });
      this.dataTable = data;
    },
    async saveScore() {
      this.$refs.scoreForm.validate(async (valid) => {
        if (valid) {
          let temp = {
            match_id: this.match_id,
            stage: this.stage,
            team_id: this.team_id,
            score: this.scoreChangeData.new_score + "",
            index: this.match_index,
          };
          let res = await this.$http.match.stage_change_s2_rank(temp);

          if (res.code == 0) {
            this.$message.success("修改成功");
            this.handleQuery();
            this.scoreChangeVisible = false;
          } else {
            this.scoreChangeVisible = true;
          }
        }
      });
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
